import React from 'react';
import heroImage from '../assets/HeroBanner.jpg';  // Import the image

const HeroBanner = () => {
  return (
    <section
      className="relative w-full h-[320px] bg-cover bg-center shadow-lg"
      style={{
        backgroundImage: `url(${heroImage})`, // You can replace this URL with your own image
      }}
    >
      {/* Transparent overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Hero text */}
      <div className="relative z-10 flex items-center justify-center h-full">
        <h1 className="text-white text-6xl font-bold drop-shadow-lg">
          One Minute Pitch
        </h1>
      </div>
    </section>
  );
};

export default HeroBanner;