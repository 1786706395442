import React from 'react';
import { motion } from 'framer-motion';


const Placeholders = () => {
  const placeholders = [
    {
      id: 1,
      title: 'Empowering Entrepreneurs with Innovation & Support',
      description: `
        At SidLabs, we recognize the challenges startups and entrepreneurs face when it comes to development—balancing innovation with affordability and reliable support. That’s where we come in.<br><br>
        
        <strong>Our Solution</strong>: SidLabs offers <strong>Web & AI development teams</strong> tailored for <strong>entrepreneurs and startups</strong>.<br><br>
        
        We bring a <strong>streamlined, cost-effective, and human-centric</strong> approach to development and support, ensuring you focus on growth while we handle the tech.<br><br>
        
        <strong>Our Secret Sauce?</strong> We combine the energy and creativity of young minds—interns, freshers, and early devs—<strong>guided by industry experts</strong>, fostering innovation that remains grounded in practical, scalable solutions.<br><br>
        
        With <strong>innovative Web & AI solutions</strong>, <strong>transparent and flexible billing</strong>, <strong>human-centered support</strong>, and <strong>expert consultation</strong>, we deliver the perfect blend of cutting-edge technology and personalized service to fuel your venture’s success.
      `,

    },
    
    
    {
      id: 2,
      title: 'Shaping the Future of Tech Development',
      description: `
        At SidLabs, we operate in the fast-evolving world of <strong>Software Development</strong>, <strong>Web Development</strong>, and <strong>Artificial Intelligence (AI) Development</strong>—industries that are at the forefront of global innovation.<br><br>
        <strong>The Market</strong>: These markets are not only growing; they are transforming the way businesses operate across the world. In 2023 alone, the global market value for these sectors reached a staggering <strong>$850 billion</strong>. This presents a massive opportunity for companies like SidLabs to deliver cutting-edge solutions that meet the demands of a tech-driven future.<br><br>
        Whether it’s creating innovative web platforms or pioneering AI solutions, SidLabs is positioned to help our clients ride the wave of digital transformation and secure their place in tomorrow’s economy.
      `,
      },
    {
      id: 3,
      title: 'Standing Out in the AI Landscape',
      description: `
        At SidLabs, we’re in good company, competing alongside industry innovators like <a href="https://www.kogo.ai/" target="_blank" rel="noopener noreferrer"><strong>Kogo.ai</strong></a> and <a href="https://www.smlgen.ai/" target="_blank" rel="noopener noreferrer"><strong>SMLgen.ai</strong></a>. These companies are pushing boundaries in AI, just as we are.<br><br>
        <strong>Our Key Differentiator?</strong> While we share a commitment to innovation and cutting-edge technology, <strong>SidLabs goes a step further with our Transparent & Flexible Billing model</strong>. Unlike traditional setups, our clients <strong>pay only for what they use</strong>, allowing them to <strong>avoid overcharges</strong> and maintain full control over their budget. This approach gives our clients peace of mind and the flexibility to scale as they grow, without unnecessary financial burdens.<br><br>
        With SidLabs, you not only get leading AI development, but also a partnership built on trust, transparency, and flexibility.
      `,
        },
    {
      id: 4,
      title: 'The Heart of SidLabs',
      description: `
        At SidLabs, our strength lies in our team—a group of <strong>ardent developers</strong> who are driven by a passion for innovation and problem-solving.<br><br>
        <strong>Current State</strong>: We currently operate with a <strong>shared resources model</strong>, allowing us to efficiently manage both long-term and short-term projects. With <strong>two monthly billing clients</strong> and <strong>several short-term assignments</strong>, we ensure that our clients receive the right expertise and resources when they need them.<br><br>
        This flexible approach allows us to deliver high-quality solutions while maintaining a strong focus on client satisfaction.<br><br>
        SidLabs isn’t just a development company; we’re a <strong>partner in your growth</strong>, with a team committed to helping you succeed.
      `,
      bgImage: 'url("/path/to/your/image4.jpg")', // Add appropriate background image path
    },
    {
      id: 5,
      title: 'Let’s Grow Together',
      description: `
        At SidLabs, we’re on a mission to empower more startups and entrepreneurs by expanding our capabilities and resources.<br><br>
        <strong>What We’re Looking For</strong>: We are actively seeking <strong>new leads and monthly billed projects</strong>. By partnering with us, you’re not only gaining access to our talented engineering team but also contributing to the growth of a company that’s passionate about helping startups succeed.<br><br>
        With your projects, we can engage more engineers, take on exciting challenges, and deliver innovative solutions that drive the future forward.<br><br>
        Join us in our journey to transform ideas into reality, and let’s make great things happen together.
      `,
        },
  ];

  return (
    <section className="w-full max-w-screen-xl mx-auto px-4 py-8">
      {placeholders.map((placeholder) => (
        <motion.div
          key={placeholder.id}
          id={`card${placeholder.id}`} // Assign an ID for linking
          className="relative rounded-lg shadow-lg overflow-hidden mb-8 transition-transform duration-300" // Add transition for smooth scaling
          style={{
            backgroundImage: `linear-gradient(90deg, rgba(27,174,204,1) 0%, rgba(22,20,22,1) 100%), url(${placeholders.bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          whileHover={{ scale: 1.05 }}
          transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        >
          <div className="absolute inset-0 bg-black opacity-50" /> {/* Faded overlay */}
          <div className="relative z-10 p-6 text-white flex flex-col justify-center h-auto text-center"> {/* Allow height to adjust based on content */}
            <h3 className="font-bold text-2xl md:text-3xl">{placeholder.title}</h3> {/* Responsive title */}
            <p className="text-lg md:text-xl"
              dangerouslySetInnerHTML={{ __html: placeholder.description }} // Render description with HTML tags
            />
          </div>
        </motion.div>
      ))}
    </section>
  );
  
  
  
  
  
  
};

export default Placeholders;