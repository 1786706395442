import React from 'react';
import NavBar from './components/NavBar';  // Import the NavBar
import HeroBanner from './components/HeroBanner';  // Import the HeroBanner component
import CardSection from './components/CardSection';  // Import CardSection
import Placeholders from './components/Placeholders'; // import placeholders
import Footer from './components/Footer';  // Import Footer

function App() {
  return (
    <div>
      <NavBar />  {/* Render the NavBar */}
      <HeroBanner />  {/* Render the HeroBanner */}
      <CardSection />
      <Placeholders />
      <Footer />
    </div>
  );
}

export default App;
