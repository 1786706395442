import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";



const Footer = () => {
  return (
    <footer
        className="pt-[50px] md:pt-[80px] pb-[20px] px-[4.65vw] text-white"
        style={{
          backgroundImage: 'linear-gradient(90deg, rgba(27,174,204,1) 0%, rgba(22,20,22,1) 100%)',
        }}
      >
      <div className="max-w-screen-2xl mx-auto">
        <div className="lg:grid grid-cols-[1fr_1fr]">
          <div className="max-w-[500px]">
            <h4 className="text-[60px] md:text-[106px] tracking-[0.01em] whitespace-nowrap font-medium">
              Let’s talk
            </h4>
            <a
              href="mailto:connect@sidlabs.net"
              className="inline-block mt-[20px] text-[24px] md:text-[32px] leading-[120%] font-semibold tracking-[0.01em]"
            >
              Connect@sidlabs.net
            </a>
            <span className="inline-block mt-[20px] font-medium text-[18px] md:text-[24px] leading-[120%]">
              +91 9805737808 (Whatsapp only)
            </span>
            <p className="mt-[20px] leading-[150%] md:text-[20px]">
              Join us on this extraordinary journey as we redefine the
              limits of human potential.
            </p>
            <div className="mt-[40px] grid grid-flow-col gap-[24px] justify-start items-center text-[32px]">
              <a
                href="https://www.linkedin.com/company/sidlabs/"
              >
                <FaLinkedinIn />
              </a>
              <a
                href="https://www.facebook.com/sidlabsonlinellp"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.instagram.com/sidlabsonlinellp/"
              >
                <FaInstagram />
              </a>
              <FaXTwitter />

              <a href="https://sidlabs.net/privacy-policy/" className="text-[16px] md:text-[18px] font-medium text-white hover:underline ml-[16px]">
  Privacy Policy
</a>

            </div>
            {/* Address paragraph moved below the social media icons */}
            <p className="mt-[20px] leading-[150%] md:text-[20px]">
              Asaf Ali Rd, Turkman Gate, Ajmeri Gate Extn, Central Delhi, New Delhi, Delhi, India, 110002.
            </p>
          </div>
          <div className="relative mt-[40px] w-full" style={{ paddingBottom: '66.67%' }}>
            <a
              href="https://maps.app.goo.gl/BpxymtzVXfomRqhF6"
              className="absolute top-0 left-0 w-full h-full block"
            >
              <img
                src="/images/Map.png"
                alt="Map"
                className="w-full h-full object-cover"
              />
            </a>
          </div>

        </div>
        <div className="mt-[40px] md:mt-[80px] bg-[white] h-[1px] md:h-[1px]" />
        <span className="inline-block mt-[20px] md:mt-[40px] text-[14px] md:text-[16px]">
          All rights reserved 2024 © Sidlabs
        </span>
      </div>
    </footer>
  );
};

export default Footer;

