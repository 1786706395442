import React from 'react';
import { motion } from 'framer-motion';
import { FaLightbulb, FaChartLine, FaBalanceScale, FaUsers, FaHandshake } from 'react-icons/fa';  // Import the icons



const CardSection = () => {
  const cards = [
    {
      id: 1,
      title: 'What Problem Do We Solve?',
      description: 'Tailored Web & AI development teams designed to empower entrepreneurs and startups for growth.',
      link: '#card1',  // Link to the corresponding placeholder
      iconComponent: <FaLightbulb />,
      
    },
    {
      id: 2,
      title: 'The Market',
      description: 'We thrive in the dynamic world of software, web, and AI development, driving innovation forward.',
      link: '#card2',
      iconComponent: <FaChartLine />,
    },
    {
      id: 3,
      title: 'Key Competitive Edge',
      description: 'SidLabs stands out with Transparent & Flexible Billing—clients pay only for what they use.',
      link: '#card3',
      iconComponent: <FaBalanceScale />,
    },
    {
      id: 4,
      title: 'Current State',
      description: 'Our shared resources model ensures efficient management of both long-term and short-term projects.',
      link: '#card4',
      iconComponent: <FaUsers />, 
    },
    {
      id: 5,
      title: 'Our Ask',
      description: 'We’re seeking new leads and monthly billed projects to fuel our growth and expand our impact.',
      link: '#card5',
      iconComponent: <FaHandshake />,
    },
  ];

  return (
    <section className="w-full py-16 bg-gray-50 flex justify-center items-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-8 w-full max-w-7xl px-4">
        {cards.map((card) => (
          <motion.a
            key={card.id}
            href={card.link}
            className="bg-white shadow-lg rounded-xl p-6 transition-all duration-300 ease-in-out hover:bg-blue-50 hover:shadow-xl cursor-pointer"
            whileHover={{ scale: 1.05, rotate: 1 }}
            transition={{ type: 'spring', stiffness: 400 }}
          >
            <div className="text-2xl ml-auto p-4 flex items-center justify-center">{card.iconComponent}</div>
            <hr></hr>
            <h3
              className="text-lg md:text-xl font-bold mb-3 p-1 bg-clip-text text-transparent text-center"
              style={{
                backgroundImage: 'linear-gradient(90deg, rgba(27,174,204,1) 0%, rgba(22,20,22,1) 100%)',
              }}
            >
              {card.title}
            </h3>
            <hr></hr>
            <p className="text-sm md:text-base text-gray-600 p-2 text-center">{card.description}</p>
          </motion.a>
        ))}
      </div>
    </section>
  );
};

export default CardSection;
