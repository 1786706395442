import React from 'react';
import { FaHome } from 'react-icons/fa';
import { motion } from 'framer-motion';  // For smooth animations

const NavBar = () => {
  return (
    <nav
      className="w-full px-6 py-4 flex justify-between items-center shadow-md fixed top-0 left-0 right-0 z-50"
      style={{
        background: 'linear-gradient(90deg, rgba(27,174,204,1) 0%, rgba(22,20,22,1) 100%)',
      }}
    >
      {/* Left Side - Company Name */}
      <motion.div
        className="text-white text-lg font-bold"
        initial={{ opacity: 0, y: -10 }} // Fade-in from top
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        SidLabs Online LLP, New Delhi, India
      </motion.div>

      {/* Right Side - Home Icon */}
      <motion.a
        href="https://sidlabs.net"
        target="_blank"
        rel="noopener noreferrer"
        className="text-white text-3xl cursor-pointer"
        whileHover={{ scale: 1.2, color: "#F97316" }}  // Smooth hover animation
        whileTap={{ scale: 0.95 }}  // Adds a small "click" effect
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        <FaHome />
      </motion.a>
    </nav>
  );
};

export default NavBar;
